.card {
    width: 340px;
    height: 500px;
    perspective: 1000px;
    /* background-color: black; */
    padding: 20px;
    ;
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flipped .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    background-color: #f0f0f0;
}

.card-front button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.card-back {
    background-color: #e0e0e0;
    transform: rotateY(180deg);
}

.card-back button {
    margin-top: 65px;
    padding: 10px 20px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.card-back button:hover,
.card-front button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}