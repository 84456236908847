html{
    scroll-behavior: smooth;
    overflow-x: hidden ;
    background-color: white;
    height: 100%;
    width: 100%;
}
 body{
    scroll-behavior: smooth;
    background-color: white;
    height: 100%;
    width: 100%;
 }